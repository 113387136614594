h1 {
    /*text-align: center;*/
    line-height: 24px;
    font-size: 24px;
    font-weight: bold;
    margin: 0 auto;
}
header {
    align-items: center;
}
.logo {
    background-size: contain;
}
