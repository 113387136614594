
.product, .product-modal {
  padding: 16px;
  border: 1px solid #f0f1f1;
  border-radius: 8px;
  margin-bottom: 8px;

  .list-group > .list-group-item {
    padding: 0;
  }

  &-price {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &-name {
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    @media (orientation: portrait) {
      padding: 16px 0;

    }
  }

  &-img {
    cursor: pointer;
    //width: 250px !important;
    @media (orientation: portrait) {
      //width: 80% !important;


    }

    img {
      border-radius: 8px;
    }
  }

  table tr td {
    font-size: 12px;
  }

  &-name {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  dt {
    font-size: 14px;
  }
}